import { FetchMethod, UnknownObjectAny } from 'types/global.types';
import {
  Certificates,
  CertificateSummary,
  CommunicationPreferencesBody,
  Coupons,
  ExtrasRequestParameters,
  ExtrasResponse,
  InterestsDataObject,
  isCertificates,
  isCertificateSummary,
  isCoupons,
  isInterests,
  isMember,
  isMemberBrief,
  isMemberSummary,
  isOffers,
  isRewards,
  isRewardsSummary,
  isTierSummary,
  KobieResponse,
  Member,
  MemberBrief,
  MemberSearchBody,
  MemberSummary,
  OfferFilters,
  Offers,
  OffersBody,
  PasswordResetDataObject,
  PasswordUpdateDataObject,
  ProductDetails,
  ProductFilters,
  Products,
  RedemptionResponse,
  Redemptions,
  Reward,
  Rewards,
  RewardsSummary,
  TierSummary,
  Transactions
} from 'types/extras';
import { ADMIN_PASSWORD, ADMIN_USERNAME, ENDPOINTS, FETCH_METHODS } from './util/constants';
import { transformMemberSummary } from 'utils/extras';

const KOBIE_API_HOST = process.env.NEXT_PUBLIC_KOBIE_API_HOST ?? '';
const TOKEN_SVC_HEADER = 'YXhTdmNBY2N0Q2xpZW50SWQ6YXhTdmNBY2N0U2VjcmV0';
const TOKEN_USER_HEADER = 'YjJjQ2xpZW50SWQ6YjJjU2VjcmV0';

interface TokenResponse extends KobieResponse {
  access_token: string;
  accountId: number;
}

export const tokenRequest = async (
  username: string,
  password: string,
  admin?: boolean
): Promise<TokenResponse | null> => {
  try {
    const data = new URLSearchParams();
    data.append('username', username);
    data.append('password', password);
    data.append('grant_type', 'password');
    const response = await fetch(`${KOBIE_API_HOST}${ENDPOINTS.AUTH_TOKEN}`, {
      method: FETCH_METHODS.POST,
      headers: {
        authorization: `Basic ${admin ? TOKEN_SVC_HEADER : TOKEN_USER_HEADER}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: data
    });
    const json = await response.json();
    if (
      typeof json?.error_description === 'string' ||
      typeof json?.error?.errorMessage === 'string'
    ) {
      return { ...json, hasError: true, success: false };
    }
    return {
      ...json,
      success: true
    };
  } catch (error) {
    console.error('ERROR FETCHING AUTH TOKEN');
    console.error(error);
    return null;
  }
};

export const refreshTokenRequest = async (
  refresh_token:string,
  admin?: boolean
): Promise<TokenResponse | null> => {
  try {
    const data = new URLSearchParams();
    data.append('refresh_token', refresh_token);
    data.append('grant_type', 'refresh_token');
    const response = await fetch(`${KOBIE_API_HOST}${ENDPOINTS.AUTH_TOKEN}`, {
      method: FETCH_METHODS.POST,
      headers: {
        authorization: `Basic ${admin ? TOKEN_SVC_HEADER : TOKEN_USER_HEADER}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: data
    });
    const json = await response.json();
    if (
      typeof json?.error_description === 'string' ||
      typeof json?.error?.errorMessage === 'string'
    ) {
      return { ...json, hasError: true, success: false };
    }
    return {
      ...json,
      success: true
    };
  } catch (error) {
    console.error('ERROR FETCHING AUTH TOKEN');
    console.error(error);
    return null;
  }
};

export const clearUserSession = async (token: string): Promise<Response> => {
  const response = await fetch(`${KOBIE_API_HOST}${ENDPOINTS.LOGOUT}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${token}` }
  });
  return response;
};

export const makeExtrasRequest = async ({
  endpoint,
  opts,
  errorMessage
}: ExtrasRequestParameters): Promise<ExtrasResponse> => {
  try {
    const response = await fetch(`${KOBIE_API_HOST}${endpoint}`, opts);
    if (response.status === 401) {
      const json = await response.json();
      return { ...json, hasError: true, success: false, logout: true }; 
    }
    if (response.status >= 400) {
      const json = await response.json();
      return { ...json, hasError: true, success: false };
    }
    const json = await response.json();
    if (
      typeof json?.error_description === 'string' ||
      typeof json?.error?.errorMessage === 'string'
    ) {
      return { ...json, hasError: true, success: false };
    }
    return { ...json, success: true };
  } catch (error) {
    console.error(errorMessage);
    console.error(error);
    return { success: false, hasError: true, error };
  }
};

export const getProfile = async (token: string) => {
  const opts = {
    method: FETCH_METHODS.GET,
    headers: {
      authorization: `Bearer ${token}`
    }
  };
  const res = await makeExtrasRequest({
    opts,
    endpoint: ENDPOINTS.PROFILE,
    errorMessage: 'ERROR GETTING MEMBER PROFILE'
  });
  if (res?.payload?.account) {
    return { ...res.payload.account, success: true };
  }
  return res;
};

export const getUserId = async (
  body: MemberSearchBody,
  token: TokenResponse | null
): Promise<string | null> => {
  const opts = {
    method: FETCH_METHODS.POST,
    headers: {
      authorization: `Bearer ${token?.access_token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  const res = await makeExtrasRequest({
    opts,
    endpoint: ENDPOINTS.MEMBER_SEARCH,
    errorMessage: 'ERROR SEARCHING MEMBERS'
  });
  if (res?.payload?.members?.length > 0) {
    return res?.payload?.members[0]?.accountUuid;
  }
  return null;
};

export const getMemberCommunicationPreferences = async (
  userId: string | number,
  adminToken?: TokenResponse | null
): Promise<ExtrasResponse> => {
  let token = adminToken;
  if (!token) {
    const admin = true;
    token = await tokenRequest(ADMIN_USERNAME, ADMIN_PASSWORD, admin);
  }
  const opts = {
    method: FETCH_METHODS.GET,
    headers: {
      authorization: `Bearer ${token?.access_token}`
    }
  };
  const res = await makeExtrasRequest({
    opts,
    endpoint: ENDPOINTS.COMMUNICATION_PREFERENCES(userId),
    errorMessage: 'ERROR GETTING MEMBER COMMUNICATION PREFERENCES'
  });
  if (res?.payload?.communicationPreference?.commCategories) {
    const marketingPrefs =
      res.payload.communicationPreference.commCategories?.find(
        ({ categoryCode }: { categoryCode: string }) => categoryCode === 'MKTG'
      ) || {};
    return { ...marketingPrefs, success: true };
  }
  return res;
};

export const updateMemberCommunicationPreferences = async (
  userId: string | number,
  data: CommunicationPreferencesBody,
  adminToken?: TokenResponse | null
): Promise<ExtrasResponse> => {
  let token = adminToken;
  if (!token) {
    const admin = true;
    token = await tokenRequest(ADMIN_USERNAME, ADMIN_PASSWORD, admin);
  }
  const opts = {
    method: FETCH_METHODS.PUT,
    body: JSON.stringify(data),
    headers: {
      authorization: `Bearer ${token?.access_token}`,
      'Content-Type': 'application/json'
    }
  };
  const res = await makeExtrasRequest({
    opts,
    endpoint: ENDPOINTS.COMMUNICATION_PREFERENCES(userId),
    errorMessage: 'ERROR UPDATING MEMBER COMMUNICATION PREFERENCES'
  });
  if (res?.payload?.id) {
    return { success: true };
  }
  return res;
};

export const getProducts = async (
  token: string,
  filters: ProductFilters = {
    payload: { criteria: { redeemSegmentCode: 'BASE', cardProductCode: 'LDCRDPRD' } }
  },
): Promise<ExtrasResponse | Products> => {
  const opts = {
    method: FETCH_METHODS.POST,
    headers: {
      authorization: `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify(filters)
  };
  const response = await makeExtrasRequest({
    opts,
    endpoint: ENDPOINTS.PRODUCTS,
    errorMessage: 'ERROR GETTING PRODUCTS'
  });
  if (response?.payload?.products) {
    return { ...response.payload, success: true };
  }
  return response;
};

export const getProductDetails = async (
  token: string,
  code: string
): Promise<ExtrasResponse | ProductDetails> => {
  const opts = {
    method: FETCH_METHODS.GET,
    headers: {
      authorization: `Bearer ${token}`
    }
  };
  const response = await makeExtrasRequest({
    opts,
    endpoint: ENDPOINTS.PRODUCT_DETAILS(code),
    errorMessage: `ERROR GETTING DETAILS FOR PRODUCT: ${code}`
  });
  if (response?.payload?.productDetails) {
    return { ...response.payload, success: true };
  }
  return response;
};

export const redeemProduct = async ({
  data,
  token
}: {
  data: {
    redemptionId: string;
    userId: string;
    emailAddress: string;
    firstName: string;
    stateProvinceCode: string;
    countryCode: string;
  };
  token: string;
}): Promise<ExtrasResponse | RedemptionResponse> => {
  const { redemptionId, userId, emailAddress, firstName, stateProvinceCode, countryCode } = data;
  const opts = {
    method: FETCH_METHODS.POST,
    headers: {
      authorization: `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      payload: {
        redemption: {
          id: userId,
          items: [
            {
              inventoryItemId: redemptionId,
              quantity: 1,
              shippingTypeCode: '1'
            }
          ],
          shippingAddress: {
            countryCode,
            emailAddress,
            firstName,
            stateProvinceCode
          }
        }
      }
    })
  };
  const response = await makeExtrasRequest({
    opts,
    endpoint: ENDPOINTS.REDEEM_PRODUCT,
    errorMessage: 'ERROR REDEEMING PROUCT'
  });
  if (response?.payload?.redemption) {
    return { ...response.payload, success: true };
  }
  return response;
};

export const updatePassword = async (
  token: string,
  userId: string | number,
  data: PasswordUpdateDataObject
): Promise<ExtrasResponse> => {
  const opts = {
    method: FETCH_METHODS.PUT,
    body: JSON.stringify(data),
    headers: {
      authorization: `Bearer ${token}`,
      'content-type': 'application/json'
    }
  };
  const response = await makeExtrasRequest({
    opts,
    endpoint: ENDPOINTS.UPDATE_PASSWORD(userId),
    errorMessage: 'ERROR UPDATING USER PASSWORD'
  });
  return response;
};

export const passwordReset = async (data: PasswordResetDataObject): Promise<ExtrasResponse> => {
  const admin = true;
  const token = await tokenRequest(ADMIN_USERNAME, ADMIN_PASSWORD, admin);
  const opts = {
    method: FETCH_METHODS.POST,
    body: JSON.stringify(data),
    headers: {
      authorization: `Bearer ${token?.access_token}`,
      'content-type': 'application/json'
    }
  };
  const response = await makeExtrasRequest({
    opts,
    endpoint: ENDPOINTS.PASSWORD_RESET,
    errorMessage: 'ERROR SENDING PASSWORD RESET REQUEST'
  });
  return response;
};

export const register = async (token: string, data: UnknownObjectAny): Promise<ExtrasResponse> => {
  const opts = {
    method: FETCH_METHODS.POST,
    body: JSON.stringify(data),
    headers: {
      authorization: `Bearer ${token}`,
      'content-type': 'application/json'
    }
  };
  const response = await makeExtrasRequest({
    opts,
    endpoint: ENDPOINTS.SIGN_UP,
    errorMessage: 'ERROR REGISTERING ACCOUNT'
  });
  return response;
};

export const getMemberInterests = async (userId: string | number): Promise<ExtrasResponse> => {
  const admin = true;
  const token = await tokenRequest(ADMIN_USERNAME, ADMIN_PASSWORD, admin);
  const opts = {
    method: FETCH_METHODS.POST,
    body: JSON.stringify({
      payload: {
        criteria: {
          dateStatus: 'ALL',
          status: 'ALL'
        }
      }
    }),
    headers: {
      authorization: `Bearer ${token?.access_token}`,
      'content-type': 'application/json'
    }
  };
  const userInterests = await makeExtrasRequest({
    opts,
    endpoint: ENDPOINTS.GET_MEMBER_INTERESTS(userId),
    errorMessage: 'ERROR GETTING MEMBER INTERSTS'
  });
  const allInterests = await makeExtrasRequest({
    opts: {
      method: FETCH_METHODS.GET,
      headers: { authorization: `Bearer ${token?.access_token}` }
    },
    endpoint: ENDPOINTS.GET_ALL_INTERESTS,
    errorMessage: 'ERROR GETTING ALL INTERSTS'
  });
  const currentUserInterests = userInterests?.payload?.memberPreference;

  const memberPreference = allInterests?.payload?.standardReferences
    ?.filter(
      ({ refCode }: { refCode: string }) =>
        !currentUserInterests?.find(
          ({ preferenceCode }: { preferenceCode: string }) => preferenceCode === refCode
        )
    )
    ?.map(({ refCode, refDescription }: { refCode: string; refDescription: string }) => ({
      status: 'N/A',
      description: refDescription,
      preferenceCode: refCode
    }))
    ?.concat(currentUserInterests);

  if (memberPreference?.length > 0) {
    return { memberPreference, success: true };
  }
  return userInterests;
};

export const updateMemberInterests = async (
  token: string,
  userId: string | number,
  data: InterestsDataObject
): Promise<ExtrasResponse> => {
  const opts = {
    method: FETCH_METHODS.POST,
    body: JSON.stringify(data),
    headers: {
      authorization: `Bearer ${token}`,
      'content-type': 'application/json'
    }
  };
  const res = await makeExtrasRequest({
    opts,
    endpoint: ENDPOINTS.UPDATE_MEMBER_INTERESTS(userId),
    errorMessage: 'ERROR SETTING MEMBER INTERSTS'
  });
  return res;
};

export const updateMemberInfo = async ({
  token,
  userId,
  body
}: {
  userId: string | number;
  token: string;
  body: string;
}): Promise<ExtrasResponse> => {
  const opts = {
    method: FETCH_METHODS.PATCH,
    headers: {
      authorization: `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  const response = await makeExtrasRequest({
    opts,
    endpoint: ENDPOINTS.MEMBER(userId),
    errorMessage: 'ERROR UPDATING MEMBER INFO'
  });
  if (response?.payload?.uuid) {
    return { success: true };
  }
  return response;
};

export const updateMemberPhone = async ({
  token,
  customerId,
  body
}: {
  customerId: string | number;
  token: string;
  body: string;
}): Promise<ExtrasResponse> => {
  const opts = {
    method: FETCH_METHODS.PATCH,
    headers: {
      authorization: `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body
  };
  const response = await makeExtrasRequest({
    opts,
    endpoint: ENDPOINTS.MEMBER_PHONE(customerId),
    errorMessage: 'ERROR UPDATING MEMBER PHONE'
  });
  if (response?.payload?.uuid) {
    return { success: true };
  }
  return response;
};

// Used for fetching user's genderCode and default addressId
export const getMember = async (
  token: string,
  userId: string | number
): Promise<Member | ExtrasResponse> => {
  const opts = {
    method: FETCH_METHODS.GET,
    headers: {
      authorization: `Bearer ${token}`
    }
  };
  const response = await makeExtrasRequest({
    opts,
    endpoint: ENDPOINTS.MEMBER(userId),
    errorMessage: 'ERROR GETTING MEMBER'
  });
  if (response?.payload?.member) {
    return { ...response.payload.member, success: true };
  }
  return response;
};

export const getMemberSummary = async (
  token: string,
  userId: string | number
): Promise<MemberSummary | ExtrasResponse> => {
  const opts = {
    method: FETCH_METHODS.GET,
    headers: {
      authorization: `Bearer ${token}`
    }
  };
  const res = await makeExtrasRequest({
    opts,
    endpoint: ENDPOINTS.MEMBER_SUMMARY(userId),
    errorMessage: 'ERROR GETTING MEMBER SUMMARY'
  });
  if (res?.payload?.memberSummary) {
    return { ...res.payload.memberSummary, success: true };
  }
  return res;
};

export const getMemberRewardsSummary = async (
  token: string,
  userId: string | number
): Promise<RewardsSummary | ExtrasResponse> => {
  const opts = {
    method: FETCH_METHODS.GET,
    headers: {
      authorization: `Bearer ${token}`
    }
  };
  const res = await makeExtrasRequest({
    opts,
    endpoint: ENDPOINTS.REWARDS_SUMMARY(userId),
    errorMessage: 'ERROR GETTING MEMBER REWARDS SUMMARY'
  });
  if (res?.payload?.rewardsSummary) {
    return { ...res.payload.rewardsSummary, success: true };
  }
  return { ...res, success: false };
};

export const getMemberCertificateSummary = async (
  token: string,
  userId: string | number
): Promise<CertificateSummary | ExtrasResponse> => {
  const opts = {
    method: FETCH_METHODS.GET,
    headers: {
      authorization: `Bearer ${token}`
    }
  };
  const res = await makeExtrasRequest({
    opts,
    endpoint: ENDPOINTS.CERTIFICATE_SUMMARY(userId),
    errorMessage: 'ERROR GETTING MEMBER CERTIFICATE SUMMARY'
  });
  if (res?.payload?.certificateSummary) {
    return { ...res.payload.certificateSummary, success: true };
  }
  return res;
};

export const getMemberBrief = async (
  token: string,
  userId: string | number
): Promise<MemberBrief | ExtrasResponse> => {
  const opts = {
    method: FETCH_METHODS.GET,
    headers: {
      authorization: `Bearer ${token}`
    }
  };
  const res = await makeExtrasRequest({
    opts,
    endpoint: ENDPOINTS.BRIEF(userId),
    errorMessage: 'ERROR GETTING MEMBER BRIEF'
  });
  if (res?.payload?.memberBrief) {
    return { ...res.payload.memberBrief, success: true };
  }
  return res;
};

export const getMemberTierSummary = async (
  token: string,
  userId: string | number
): Promise<TierSummary | ExtrasResponse> => {
  const opts = {
    method: FETCH_METHODS.GET,
    headers: {
      authorization: `Bearer ${token}`
    }
  };
  const res = await makeExtrasRequest({
    opts,
    endpoint: ENDPOINTS.TIER_SUMMARY(userId),
    errorMessage: 'ERROR GETTING MEMBER TIER SUMMARY'
  });
  if (res?.payload?.tierSummary) {
    return { ...res.payload.tierSummary, success: true };
  }
  return res;
};

export const getMemberCertificates = async (
  token: string,
  userId: string | number
): Promise<Certificates | ExtrasResponse> => {
  const opts = {
    method: FETCH_METHODS.GET,
    headers: {
      authorization: `Bearer ${token}`
    }
  };
  const res = await makeExtrasRequest({
    opts,
    endpoint: `${ENDPOINTS.CERTIFICATES(userId)}?startDate=2022-08-01T12:00:00.000-0500`,
    errorMessage: 'ERROR GETTING MEMBER CERTIFICATES'
  });
  if (res?.payload?.certificates) {
    return { certificates: res.payload.certificates, success: true };
  }
  return res;
};

export const getMemberRewards = async (
  token: string,
  userId: string | number,
  startDate: string,
  endDate?: string
): Promise<Rewards | ExtrasResponse> => {
  const opts = {
    method: FETCH_METHODS.GET,
    headers: {
      authorization: `Bearer ${token}`
    }
  };
  const res = await makeExtrasRequest({
    opts,
    endpoint: `${ENDPOINTS.REWARDS(userId)}?startDate=${startDate}${
      endDate ? `&endDate=${endDate}` : ''
    }`,
    errorMessage: 'ERROR GETTING MEMBER REWARDS'
  });
  if (res?.payload?.rewards) {
    const [{ redemptions }, { transactions }] = await Promise.all([
      getMemberRedemptions(token, userId, startDate, endDate),
      getMemberTransactions(token, userId, startDate, endDate)
    ]);
    const _redemptions = redemptions
      .reverse()
      .map((redemption) => {
        let activeDate;
        let bonusDescription;
        const activity = redemption?.redemptionItemInfoDTOList?.[0]?.itemDescription;
        const amount = redemption?.rewardAmount;
        const redemptionId = redemption?.redemptionId;
        const matchingReward = res.payload.rewards?.find(
          (reward: Reward) => redemptionId === reward.redemptionId
        );
        if (matchingReward) {
          activeDate = matchingReward?.activeDate;
          bonusDescription = matchingReward?.bonusDescription;
        } else {
          return null;
        }
        return { ...matchingReward, ...redemption, activeDate, activity, amount, bonusDescription };
      })
      .filter((redemption) => !!redemption);
    const _transactions = transactions
      .reverse()
      .map((transaction) => {
        let bonusDescription;
        let activeDate;
        const activity = transaction?.transTypeDescription;
        const amount = transaction?.rewardsTotal;
        const transId = transaction?.transId;
        const matchingReward = res.payload.rewards?.find(
          (reward: Reward) => transId === reward.transId
        );
        if (matchingReward) {
          activeDate = matchingReward?.activeDate;
          bonusDescription = matchingReward?.bonusDescription;
        } else {
          return null;
        }

        if (transaction?.transTypeCode === 'SALE') {
          bonusDescription = 'Purchase';
        } else if (transaction?.transTypeCode === 'RETURN') {
          bonusDescription = 'Return';
        } else if (transaction?.transTypeCode === 'EXCHANGE') {
          bonusDescription = 'Exchange';
        }
        return {
          ...matchingReward,
          ...transaction,
          activeDate,
          activity,
          amount,
          bonusDescription
        };
      })
      .filter((transaction) => !!transaction);

    const goodWill = res.payload.rewards
      ?.filter(({ bonusType }: { bonusType?: string }) => bonusType === 'GOODWILL')
      ?.map((reward: Reward) => ({ activity: '', ...reward }));
    const bonusRewards = res.payload.rewards
      ?.filter(({ bonusType }: { bonusType?: string }) => bonusType === 'BRANDLD')
      ?.map((reward: Reward) => ({ activity: '', ...reward, bonusDescription: 'Bonus Points' }));
    const expiredRewards = res.payload.rewards
      ?.filter(({ bonusType }: { bonusType?: string }) => bonusType === 'EXPIRE')
      ?.map((reward: Reward) => ({
        ...reward,
        activity: '',
        bonusDescription: 'Points Expiration'
      }));

    const rewards = [
      ..._redemptions,
      ..._transactions,
      ...goodWill,
      ...bonusRewards,
      ...expiredRewards
    ].sort((a, b) => (new Date(a.activeDate) < new Date(b.activeDate) ? -1 : 1));

    return { rewards, success: true };
  }
  return res;
};

export const getMemberAccountOffers = async (
  token: string,
  userId: string | number,
  filters: OfferFilters = { payload: { registrationStatus: 'ALL', status: 'A' } }
): Promise<Offers | ExtrasResponse> => {
  const opts = {
    method: FETCH_METHODS.POST,
    headers: {
      authorization: `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify(filters)
  };
  const res = await makeExtrasRequest({
    opts,
    endpoint: ENDPOINTS.OFFERS(userId),
    errorMessage: 'ERROR GETTING MEMBER OFFERS'
  });
  if (res?.payload?.offers) {
    return { offers: res.payload.offers, success: true };
  }
  return res;
};

export const getMemberAccountCoupons = async (
  token: string,
  userId: string | number
): Promise<Coupons | ExtrasResponse> => {
  const opts = {
    method: FETCH_METHODS.GET,
    headers: {
      authorization: `Bearer ${token}`
    }
  };

  const response = await makeExtrasRequest({
    opts,
    endpoint: ENDPOINTS.COUPONS(userId),
    errorMessage: 'ERROR GETTING MEMBER OFFERS'
  });

  if (response?.payload?.coupons) {
    return { coupons: response.payload.coupons, success: true };
  }
  return response;
};

export const registerMemberAccountOffer = async ({
  token,
  userId,
  offerId,
  offerCode,
  body,
  method = FETCH_METHODS.PUT
}: {
  token: string;
  userId: string | number;
  offerId?: string;
  offerCode?: string;
  body: OffersBody;
  method: FetchMethod;
}): Promise<ExtrasResponse> => {
  const opts = {
    method,
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  // let endpoint = offerCode
  // ? ENDPOINTS.REGISTER_OFFER(userId)
  // : ENDPOINTS.REGISTER_BIRTHDAY_OFFER(userId, offerId as string);
  // if (offerCode?.includes('@')) endpoint = ENDPOINTS.REGISTER_OFFER(userId)
  // if (offerCode?.includes('#')) endpoint = ENDPOINTS.REGISTER_BIRTHDAY_OFFER(userId, offerCode)
    
  // const response = await makeExtrasRequest({
  //   opts,
  //   endpoint,
  //   errorMessage: 'ERROR REGISTERING OFFER'
  // });
  const response = await makeExtrasRequest({
    opts,
    endpoint: offerCode
      ? ENDPOINTS.REGISTER_OFFER(userId)
      : ENDPOINTS.REGISTER_BIRTHDAY_OFFER(userId, offerId as string),
    errorMessage: 'ERROR REGISTERING OFFER'
  });
  if (response?.payload?.id) {
    return { success: true };
  }
  return response;
};

export const getMemberTransactions = async (
  token: string,
  userId: string | number,
  startDate: string,
  endDate?: string
): Promise<Transactions> => {
  const opts = {
    method: FETCH_METHODS.GET,
    headers: {
      authorization: `Bearer ${token}`
    }
  };
  const response = await makeExtrasRequest({
    opts,
    endpoint: `${ENDPOINTS.TRANSACTIONS(userId)}?startDate=${startDate}${
      endDate ? `&endDate=${endDate}` : ''
    }`,
    errorMessage: 'ERROR GETTING MEMBER TRANSACTIONS'
  });
  if (response?.payload?.transactions) {
    return { ...response.payload, success: true };
  } else {
    return { transactions: [], success: false };
  }
};

export const getMemberRedemptions = async (
  token: string,
  userId: string | number,
  startDate: string,
  endDate?: string
): Promise<Redemptions> => {
  const opts = {
    method: FETCH_METHODS.GET,
    headers: {
      authorization: `Bearer ${token}`
    }
  };
  const response = await makeExtrasRequest({
    opts,
    endpoint: `${ENDPOINTS.REDEMPTIONS(userId)}?startDate=${startDate}${
      endDate ? `&endDate=${endDate}` : ''
    }`,
    errorMessage: 'ERROR GETTING MEMBER Redemptions'
  });
  if (response?.payload?.redemptions) {
    return { ...response.payload, success: true };
  } else {
    return { redemptions: [], success: false };
  }
};

export const gatherMemberData = async (
  token: string,
  userId: string | number
): Promise<UnknownObjectAny> => {
  const [
    member,
    summary,
    rewardsSummary,
    certificates,
    certificateSummary,
    brief,
    tierSummary,
    offers,
    interests
  ] = await Promise.all([
    getMember(token, userId),
    getMemberSummary(token, userId),
    getMemberRewardsSummary(token, userId),
    getMemberCertificates(token, userId),
    getMemberCertificateSummary(token, userId),
    getMemberBrief(token, userId),
    getMemberTierSummary(token, userId),
    getMemberAccountOffers(token, userId),
    getMemberInterests(userId)
  ]);
  // Second Promise.all, TS only allows 10 items per.
  const [coupons] = await Promise.all([getMemberAccountCoupons(token, userId)]);

  // This profile object is set up to map to the extras.model state object for initial state
  const profile = {
    ...(isMemberSummary(summary) &&
      isMember(member) && {
        summary: transformMemberSummary(summary, member)
      }),
    ...(isRewardsSummary(rewardsSummary) && {
      rewardsSummary
    }),
    ...(isCertificates(certificates) && {
      certificates: certificates.certificates
    }),
    ...(isCertificateSummary(certificateSummary) && {
      certificateSummary
    }),
    ...(isTierSummary(tierSummary) && {
      tierSummary
    }),
    ...(isOffers(offers) && {
      offers: offers.offers
    }),
    ...(isCoupons(coupons) && {
      coupons: coupons.coupons
    }),
    ...(isInterests(interests) && {
      interests: interests.memberPreference
    })
  };
  if (profile?.summary?.pointsInfo && isMemberBrief(brief)) {
    profile.summary.pointsInfo.tierExpireDate = brief.tierExpireDate;
  }
  return profile;
};

export const checkTokenExpiry = async(userId:string|number, token:string, refreshToken?:string) =>{
  try{
  const opts = {
    method: FETCH_METHODS.GET,
    headers: {
      authorization: `Bearer ${token}`
    }
  };
  const endpoint = ENDPOINTS.MEMBER(userId)
  const response = await fetch(`${KOBIE_API_HOST}${endpoint}`, opts);
  if (response.status === 401 && refreshToken) {
    const refreshResponse = await refreshTokenRequest(refreshToken);
    return {refreshedToken: refreshResponse?.access_token}
  }
  return {refreshedToken: null}
  } catch (error) {
    return {refreshedToken: null}
  }
}
