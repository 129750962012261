import { flow, Instance, types as t } from 'mobx-state-tree';
import * as extrasApi from 'api/extras';
import { InterestsFormObject } from 'types/extras';
import { FETCH_METHODS, STATUSES } from 'api/util/constants';
import { formatDate, isChurnedOffer, isFrequencyOffer, isLoadableOffer, unMaskPhoneValue } from 'utils';
import {
  AUTH_TOKEN_COOKIE,
  EXTRAS_TIER_CODE,
  EXTRAS_TIER_NAME,
  EXTRAS_TIER2_THRESHOLD,
  months,
  years,
  USER_ID_COOKIE,
  CUSTOMER_ID_COOKIE,
  REFRESH_TOKEN_COOKIE
} from 'utils/constants';
import { removeCookies } from 'cookies-next';
import { HeroCardData } from 'components/MyExtras/HeroCard';
import { UnknownObjectAny } from 'types/global.types';
import { getFormattedDate } from 'api/util';
import { ADMIN_USERNAME, ADMIN_PASSWORD } from '../api/util/constants';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import {
  convertDateToPST,
  convertOfferExpirationDate,
  // getDate,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  getThreeMonthsAgo
} from 'api/utils';

const reformatStringDate = (dateString?: string) => {
  if (!dateString) {
    return dateString;
  }
  try {
    const dateWithoutOffset = new Date(dateString.trim().split(/[ T]/)[0]);
    const offset = dateWithoutOffset?.getTimezoneOffset?.() * 60000;
    const parsedDate = new Date(+dateWithoutOffset + offset);

    return [parsedDate.getMonth() + 1, parsedDate.getDate(), parsedDate.getFullYear()]
      .map((value) => {
        if (value < 10) {
          return `0${value}`;
        }
        return value;
      })
      .join('/');
  } catch (error) {
    console.error(`Could not reformat date string: ${dateString}`);
    console.error(error);
  }
};

export const SessionModel = t.model('SessionMOdel', {
  isOldSession: t.maybe(t.boolean)
})

export const CommunicationPreferencesModel = t.model('CommunicationPreferences', {
  categoryCode: t.maybe(t.string),
  availableChannels: t.maybe(
    t.array(
      t.model({
        channelCode: t.string,
        communicationId: t.number,
        communicationDescription: t.string,
        optedInInd: t.boolean,
        communicationTypeCode: t.string
      })
    )
  )
});

export const HigherTierModel = t.model('HigherTier', {
  tierCode: t.string,
  tierDescription: t.string,
  spendReq: t.number
});

export const TierSummaryModel = t.model('TierSummary', {
  tierCode: t.string,
  tierDescription: t.string,
  tierEnrollDate: t.maybeNull(t.string),
  tierExpireDate: t.maybeNull(t.string),
  higherTiers: t.maybeNull(t.array(HigherTierModel)),
  currentYearTierQualification: t.maybeNull(
    t.model({
      eligibleSpendAmt: t.maybeNull(
        t.model({
          yearly: t.maybeNull(t.number)
        })
      )
    })
  )
});

export const InterestModel = t.model('Interest', {
  preferenceCode: t.string,
  description: t.string,
  status: t.string
});

export const ProductModel = t.model('Product', {
  name: t.string,
  description: t.string,
  brand: t.maybeNull(t.string),
  productTerms: t.maybeNull(t.string),
  id: t.number,
  code: t.string,
  minAmount: t.number,
  maxAmount: t.number,
  // Not sure what the images data will look like - will need to update this most likely
  images: t.maybeNull(
    t.array(t.model({ imageType: t.maybeNull(t.string), imageUrl: t.maybeNull(t.string) }))
  ),
  categories: t.maybeNull(
    t.array(t.maybe(t.model({ code: t.maybeNull(t.string), category: t.maybeNull(t.string) })))
  ),
  // whether the product is meant to be redeemable on the website
  webRedeemableInd: t.maybeNull(t.boolean),
  inventoryItemId: t.maybeNull(t.number)
});

export const OfferModel = t.model('Offer', {
  accountOfferId: t.maybeNull(t.number),
  offerCode: t.maybeNull(t.string),
  description: t.maybeNull(t.string),
  offerTerms: t.maybeNull(t.string),
  status: t.maybeNull(t.string),
  registeredDate: t.maybeNull(t.string),
  startedDate: t.maybeNull(t.string),
  expiresDate: t.maybeNull(t.string),
  imageLink: t.maybeNull(t.string),
  offerLink: t.maybeNull(t.string)
});

// build this out when we have a working example
export const CertificateModel = t.model('Certificate', {
  quantity: t.number,
  redemptionId: t.number,
  redeemedAmount: t.number,
  certAmountUsed: t.number,
  certAmountRemaining: t.number,
  itemName: t.string,
  certIssueDate: t.string,
  certExpireDate: t.maybeNull(t.string),
  certNumber: t.maybeNull(t.string)
});

export const CouponModel = t.model('Coupons', {
  quantity: t.maybeNull(t.number),
  redemptionId: t.maybeNull(t.number),
  redeemedAmount: t.maybeNull(t.number),
  certAmountUsed: t.maybeNull(t.number),
  certAmountRemaining: t.maybeNull(t.number),
  itemName: t.maybeNull(t.string),
  certIssueDate: t.maybeNull(t.string),
  certExpireDate: t.maybeNull(t.string),
  certNumber: t.maybeNull(t.string),
  accountOfferId: t.maybeNull(t.number),
  offerCode: t.maybeNull(t.string),
  description: t.maybeNull(t.string),
  offerTerms: t.maybeNull(t.string),
  expiresDate: t.maybeNull(t.string),
  offerLink: t.maybeNull(t.string),
  couponCode: t.maybeNull(t.string),
  unlimitedUseInd: t.maybeNull(t.boolean),
  availableUses: t.maybeNull(t.number),
  accountCouponIssueStartDate: t.maybeNull(t.string),
  accountCouponIssueExpireDate: t.maybeNull(t.string),
  accountId: t.maybeNull(t.number),
  customerId: t.maybeNull(t.number),
  couponIssueId: t.maybeNull(t.number),
  couponTypeCode: t.maybeNull(t.string),
  couponTypeDescription: t.maybeNull(t.string),
  allowableUses: t.maybeNull(t.number),
  couponValidStartDate: t.maybeNull(t.string),
  couponValidEndDate: t.maybeNull(t.string),
  programCode: t.maybeNull(t.string),
  campaignCode: t.maybeNull(t.string),
  promotionCode: t.maybeNull(t.string),
  offerDescription: t.maybeNull(t.string),
  couponUpdateDate: t.maybeNull(t.string)
});

export const CertificateSummaryModel = t.model('CertificateSummary', {
  expiredTotal: t.number,
  availableTotal: t.number,
  lifetimeTotal: t.number,
  rewardsToNextCertificate: t.number,
  currencyCode: t.string,
  rewardType: t.string,
  rewardTypeDescription: t.string,
  autoRedeemInd: t.boolean,
  certsAvailableCount: t.number
});

export const MonthlyRewardsModel = t.model('MonthlyRewards', {
  year: t.string,
  month: t.string,
  earnedAmount: t.maybe(t.number),
  rewardsAmountToExpire: t.maybe(t.number)
});

export const RewardsSummaryModel = t.model('RewardsSummary', {
  baseRewardsAvailable: t.number,
  bonusRewardsAvailable: t.number,
  totalRewardsAvailable: t.number,
  totalRewardsPending: t.number,
  rewardsEarnedLifetime: t.number,
  rewardsExpiredLifetime: t.number,
  rewardsRedeemedLifetime: t.number,
  ytdTierQualifyingValue: t.number,
  conversionPointsPreviouslyRedeemed: t.maybe(t.number),
  monthlyRewardsSummary: t.array(MonthlyRewardsModel),
  monthlyRewardsToExpire: t.array(MonthlyRewardsModel)
});

export const RewardModel = t.model('Reward', {
  amount: t.string,
  bonusCode: t.maybeNull(t.string),
  bonusType: t.maybe(t.string),
  bonusDescription: t.maybeNull(t.string),
  rewardId: t.number,
  activeDate: t.string,
  expireDate: t.maybeNull(t.string),
  tqv: t.number,
  canViewDetail: t.boolean,
  redemptionId: t.maybeNull(t.number),
  transId: t.maybeNull(t.number),
  activity: t.string
});

const MemberSummaryModel = t.model('MemberSummary', {
  firstName: t.string,
  lastName: t.string,
  phoneNumber: t.string,
  emailAddress: t.string,
  userName: t.string,
  enrollDate: t.string,
  dateOfBirth: t.maybeNull(t.string),
  emailDeliverabilityCode: t.maybeNull(t.string),
  phoneDeliverabilityCode: t.maybeNull(t.string),
  genderCode: t.maybeNull(t.string),
  pointsInfo: t.model({
    tierCode: t.string,
    tierExpireDate: t.maybeNull(t.string),
    canEarn: t.boolean,
    canRedeem: t.boolean,
    cardLastFour: t.maybeNull(t.string),
    balance: t.number,
    pending: t.number,
    ytdVisits: t.number,
    lifeVisits: t.number,
    rewardTimeToLive: t.maybeNull(t.string)
  }),
  address: t.model({
    addressId: t.maybeNull(t.number),
    addressLine1: t.maybeNull(t.string),
    addressLine2: t.maybeNull(t.string),
    city: t.maybeNull(t.string),
    stateProvinceCode: t.maybeNull(t.string),
    postalCode: t.maybeNull(t.string),
    countryCode: t.maybeNull(t.string)
  })
});

export interface MobxMemberSummary extends Instance<typeof MemberSummaryModel> { }

export const ExtrasProfileModel = t.model('Profile', {
  summary: t.maybe(MemberSummaryModel),
  rewardsSummary: t.maybe(RewardsSummaryModel),
  rewards: t.maybe(t.array(RewardModel)),
  certificateSummary: t.maybe(CertificateSummaryModel),
  certificates: t.maybe(t.array(CertificateModel)),
  offers: t.maybeNull(t.array(OfferModel)),
  tierSummary: t.maybeNull(TierSummaryModel),
  products: t.maybeNull(t.array(ProductModel)),
  communicationPreferences: t.maybe(CommunicationPreferencesModel),
  interests: t.maybeNull(t.array(InterestModel)),
  coupons: t.maybeNull(t.array(CouponModel)),
  session: t.maybeNull(t.maybe(SessionModel))
});

export const ExtrasStoreModel = t
  .model({
    profile: t.optional(ExtrasProfileModel, {}),
    token: t.optional(t.string, () => ''),
    userId: t.optional(t.string, () => ''),
    customerId: t.optional(t.string, () => ''),
    session: t.optional(SessionModel, {})
  })
  .views((self) => ({
    get isOldSession(){
      return self?.session?.isOldSession
    },
    get isLoggedIn() {
      return !!self.profile.summary;
    },
    get memberId() {
      return self?.profile?.summary?.userName || '';
    },
    get firstName() {
      return self?.profile?.summary?.firstName;
    },
    get lastName() {
      return self?.profile?.summary?.lastName;
    },
    get dob() {
      return (self?.profile?.summary?.dateOfBirth || '').replace(/\s/, 'T');
    },
    get email() {
      return self?.profile?.summary?.emailAddress;
    },
    get balance() {
      return self?.profile?.summary?.pointsInfo?.balance || 0;
    },
    get phoneNumber() {
      return self?.profile?.summary?.phoneNumber;
    },
    get genderCode() {
      return self?.profile?.summary?.genderCode;
    },
    get tierCode() {
      return self?.profile?.summary?.pointsInfo?.tierCode;
    },
    get isRequalified() {
      return (
        self?.profile?.tierSummary?.tierCode === EXTRAS_TIER_CODE.TIER2 &&
        (self?.profile?.tierSummary?.currentYearTierQualification?.eligibleSpendAmt?.yearly ?? 0) >=
        EXTRAS_TIER2_THRESHOLD
      );
    },
    formattedDob() {
      if (this.dob) {
        const formattedDate = new Date(this.dob).toLocaleDateString('US');
        const [month, day, year] = formattedDate.split('/');
        return `${month?.length === 1 ? `0${month}` : month}/${day?.length === 1 ? `0${day}` : day
          }/${year}`;
      }
      return '';
    },
    communicationPrefs(type = 'email') {
      const communicationPreferences = self?.profile?.communicationPreferences;
      if (!communicationPreferences) {
        return { communicationId: 0 };
      }
      const prefTypeCode = type === 'email' ? 'E' : 'P';
      const prefs = communicationPreferences?.availableChannels?.find(
        ({ channelCode }) => channelCode === prefTypeCode
      );
      if (!prefs) {
        return { communicationId: 0 };
      }
      return prefs;
    },
    deliverabilityMessage(type = 'email') {
      const communicationPreferences = self?.profile?.communicationPreferences;
      if (!communicationPreferences) {
        return '';
      }
      const prefTypeCode = type === 'email' ? 'E' : 'P';
      const prefs = communicationPreferences?.availableChannels?.find(
        ({ channelCode }) => channelCode === prefTypeCode
      );
      if (!prefs) {
        return '';
      }
      return prefs?.optedInInd ? 'Opted-In' : 'Not Opted-In';
    },
    tierName() {
      const tierCode = self?.profile?.summary?.pointsInfo?.tierCode;
      return tierCode === EXTRAS_TIER_CODE.TIER1
        ? EXTRAS_TIER_NAME.TIER1
        : tierCode === EXTRAS_TIER_CODE.TIER2
          ? EXTRAS_TIER_NAME.TIER2
          : '';
    },
    memberSince() {
      return formatDate(self?.profile?.summary?.enrollDate || '') || '';
    },
    address() {
      if (!self?.profile?.summary) {
        return {};
      }
      return self.profile.summary?.address || {};
    },
    nextTierRequirements() {
      if (!self?.profile?.tierSummary || !self?.profile?.tierSummary?.higherTiers?.length) {
        return {};
      }
      const spendReq =
        self?.profile?.tierSummary?.higherTiers?.[0]?.spendReq || EXTRAS_TIER2_THRESHOLD;

      return {
        amountSpentTowardsNextTier: EXTRAS_TIER2_THRESHOLD - spendReq,
        remainingSpendRequirement: spendReq
      };
    },
    tierRequalificationRequirements() {
      if (
        !self?.profile?.tierSummary ||
        self?.profile?.tierSummary?.tierCode === EXTRAS_TIER_CODE.TIER1
      ) {
        return {};
      }
      const amountSpent =
        self?.profile?.tierSummary?.currentYearTierQualification?.eligibleSpendAmt?.yearly ?? 0;
      return {
        amountSpentTowardsRequalification: amountSpent,
        remainingSpendRequalificationRequirement: EXTRAS_TIER2_THRESHOLD - amountSpent
      };
    },
    heroCardData(): HeroCardData | UnknownObjectAny {
      if (!self?.profile?.summary) {
        return {};
      }
      const data = {
        memberId: this.memberId,
        memberSince: this.memberSince(),
        pointsInfo: self?.profile?.summary?.pointsInfo,
        tierName: this.tierName(),
        nextTierRequirements: this.nextTierRequirements(),
        tierRequalificationRequirements: this.tierRequalificationRequirements(),
        isRequalified: this.isRequalified
      };
      return data;
    },
    accountOffers() {
      let offers: any = self.profile.offers || []
      if (!offers.length) {
        return [];
      }
      offers = offers
        ?.filter(
          // Filtering the offer data if it does not contains 'CPN'
          (_offer: any) => !isLoadableOffer(_offer.offerCode)
        )
      offers = offers
        ?.filter(({ expiresDate, startedDate, description, offerCode }: any) => {
          const startDate = +new Date(startedDate as string);
          const expirationDate = +new Date(expiresDate as string);

          const now = Date.now();
          if (startDate > now) {
            return false;
          // } else if (expiresDate && expirationDate < now) {
          } else if (expirationDate < now) {
            return false;
          }
          if (
            description?.toLowerCase()?.includes('birthday') ||
            offerCode === 'ANNIVERSRY1M' ||
            offerCode?.substring(0, 3) === 'SWP' ||
            (offerCode && isChurnedOffer(offerCode))
          ) {
            return false;
          }
          return true;
        })
        ?.sort((a: any, b: any) =>a?.offerCode?.localeCompare(b?.offerCode))
        ?.map(({ description, expiresDate, imageLink, ...offer }: any) => {
          let expirationDate;

          if (expiresDate === null) {
            expirationDate = 'No expiration date';
          } else if (offer.offerCode && isChurnedOffer(offer.offerCode)) {
            expirationDate = `Expires ${moment
              // .utc(new Date(expiresDate).toUTCString())
              .utc(new Date(convertOfferExpirationDate(expiresDate, 2, true)).toUTCString())
              .format('MMM DD, YYYY')}`;
          } else {
            expirationDate = `Expires ${moment
              .utc(new Date(expiresDate).toUTCString())
              .format('MMM DD YYYY')}`;
          }

          return {
            ...offer,
            expirationDate,
            headline: description,
            image: {
              url:
                !imageLink || (typeof imageLink === 'string' && !imageLink.startsWith('http'))
                  ? '/assets/location-placeholder.jpeg'
                  : imageLink
            }
          };
        });
      return offers;
    },
    accountChurnedOffers() {
      let offers: any = self.profile.offers || []
      if (!offers.length) {
        return [];
      }
      offers = offers
        .filter(
          ({ offerCode }: any) => offerCode && isChurnedOffer(offerCode) && !isLoadableOffer(offerCode)
        )

      offers = offers
        ?.filter(({ expiresDate, startedDate }: any) => {
          // const startDate = getDate(startedDate as string)//+convertDateToPST(startedDate as string);
          // const expirationDate = getDate(expiresDate as string) // +convertOfferExpirationDate(expiresDate as string);
          // const now = getDate(`${new Date()}`) //Date.now();
          const startDate = +convertDateToPST(startedDate as string);
          const expirationDate = +convertOfferExpirationDate(expiresDate as string);
          const now = Date.now();
          if (startDate > now) {
            return false;
          } else if (expirationDate < now) {
          // } else if (expiresDate && expirationDate < now) {
            return false;
          }
          return true;
        })
        ?.sort((a: any, b: any) =>a?.offerCode?.localeCompare(b?.offerCode))
        ?.map(({ expiresDate, imageLink, ...offer }: any) => {
          return {
            ...offer,
            endDate:
              expiresDate === null
                ? 'No expiration date'
                : `Expires ${moment
                  // .utc(new Date(expiresDate).toUTCString())
                  .utc(new Date(convertOfferExpirationDate(expiresDate, 2, true)).toUTCString())
                  .format('MMM DD, YYYY')}`,
            image: {
              url:
                !imageLink || (typeof imageLink === 'string' && !imageLink.startsWith('http'))
                  ? '/assets/location-placeholder.jpeg'
                  : imageLink
            }
          };
        });
      return offers;
    },
    birthdayOffer() {
      if (!self?.profile?.offers?.length) {
        return null;
      }
      const matchingOffer = self?.profile?.offers
        ?.filter(({ expiresDate, startedDate }) => {
          const startDate = +new Date(startedDate as string);
          const expirationDate = +new Date(expiresDate as string);
          const now = Date.now();
          if (startDate > now) {
            return false;
          } else if (expirationDate < now) {
          // } else if (expiresDate && expirationDate < now) {
            return false;
          }
          return true;
        })
        ?.find(({ description = '' }) => description?.toLowerCase()?.includes('birthday'));
      if (matchingOffer) {
        return {
          ...matchingOffer,
          expiresDate: reformatStringDate(matchingOffer.expiresDate as string)
        };
      }
      return undefined;
    },
    getOffer(offerCode: string) {
      if (!self?.profile?.offers?.length) {
        return null;
      }
      const offer = self?.profile?.offers?.find((_offer) => _offer?.offerCode === offerCode);
      if (offer) {
        const { expiresDate, description, imageLink, registeredDate } = offer;
        let endDate;

        if (expiresDate === null) {
          endDate = 'No expiration date';
        } else if (isChurnedOffer(offerCode)) {
          endDate = `Expires ${moment
            // .utc(new Date(expiresDate).toUTCString())
            .utc(new Date(convertOfferExpirationDate(expiresDate, 2, true)).toUTCString())
            .format('MMM DD, YYYY')}`;
        } else {
          endDate = `${moment.utc(new Date(expiresDate).toUTCString()).format('MMM DD YYYY')}`;
        }

        return {
          ...offer,
          endDate,
          headline: description,
          image: {
            url:
              !imageLink || (typeof imageLink === 'string' && !imageLink.startsWith('http'))
                ? '/assets/location-placeholder.jpeg'
                : imageLink
          },
          isRegistered: !!registeredDate
        };
      }
      return null;
    },
    sweepstakesOffers() {
      if (!self?.profile?.offers?.length) {
        return null;
      }
      const matchingOffers = self?.profile?.offers
        ?.filter(
          (_offer) =>
            _offer?.offerCode?.substring(0, 3) === 'SWP' || _offer?.offerCode === 'ANNIVERSRY1M'
        )
        ?.filter(({ expiresDate, startedDate }) => {
          // const startDate = getDate(startedDate as string) //+convertDateToPST(startedDate as string);
          // const expirationDate = getDate(expiresDate as string) //+convertOfferExpirationDate(expiresDate as string);
          // const now = getDate(`${new Date()}`)// Date.now();
          const startDate = +convertDateToPST(startedDate as string);
          const expirationDate = +convertOfferExpirationDate(expiresDate as string);
          const now = Date.now();
          if (startDate > now) {
            return false;
          } else if (expirationDate < now) {
          // } else if (expiresDate && expirationDate < now) {
            return false;
          }
          return true;
        })
        ?.sort((a: any, b: any) =>a?.offerCode?.localeCompare(b?.offerCode));
      if (matchingOffers?.length > 0) {
        return matchingOffers.map((offer) => {
          const { expiresDate, description, imageLink, registeredDate } = offer;
          return {
            ...offer,
            endDate:
              expiresDate === null
                ? 'No expiration date'
                : `Ends: ${moment
                  // .utc(new Date(expiresDate).toUTCString())
                  .utc(new Date(convertOfferExpirationDate(expiresDate, 2, true)).toUTCString())
                  .format('MMM DD YYYY')}`,
            headline: description,
            image: {
              url:
                !imageLink || (typeof imageLink === 'string' && !imageLink.startsWith('http'))
                  ? '/assets/location-placeholder.jpeg'
                  : imageLink
            },
            isRegistered: !!registeredDate,
            isTargetedOffer: !!offer?.offerCode?.includes('SWPTGT')
          };
        });
      }
      return null;
    },
    activeVouchers(includeCoupons: boolean) {
      if (!self?.profile?.certificates?.length && !self?.profile?.coupons?.length) {
        return [];
      }
      const formatter = Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' });
      // https://64labs.atlassian.net/browse/LDX-292
      // Kobie creates a new voucher every single time the user uses the voucher for a purchase. So if they have a $10 voucher and use $2 of it, a new $8 voucher is created.
      // If a voucher is used several times without depleting it, there will be several vouchers created.
      // Here we are filtering out duplicates - the array is reversed to give us the latest iteration of the voucher first and then reversed again to show the new filtered array in the intended order
      const activeVouchers = self.profile?.certificates
        ?.slice()
        ?.reverse()
        // filter out expired certs
        ?.filter(
          ({ certExpireDate, certAmountRemaining, certNumber }, index, self) =>
            +new Date(certExpireDate as string) > +new Date() &&
            certAmountRemaining > 0 &&
            self.findIndex((_) => _.certNumber === certNumber) === index
        )
        ?.reverse()
        ?.map(({ certIssueDate, certExpireDate, certAmountRemaining, ...cert }) => ({
          ...cert,
          certIssueDate: reformatStringDate(certIssueDate as string),
          certExpireDate:
            certExpireDate === null
              ? 'No expiration date'
              : reformatStringDate(certExpireDate as string),
          balance: formatter.format(certAmountRemaining)
        }));

      if (includeCoupons && self.profile?.coupons && self.profile.coupons.length > 0) {
        const coupons = self.profile.coupons?.filter(
          ({ availableUses }) => availableUses && availableUses > 0
        );
        const activeVouchersAndCoupons = activeVouchers
          ?.concat(coupons as any)
          .map((item: any) => {
            const formattedStartDate = reformatStringDate(
              item?.accountCouponIssueStartDate as string
            );
            const formattedEndDate = reformatStringDate(
              item?.accountCouponIssueExpireDate as string
            );
            const dateSliced = item?.certIssueDate?.split('/') ?? formattedStartDate?.split('/');
            return {
              ...item,
              itemName: item.itemName ?? item.offerDescription,
              certNumber: item.certNumber ?? item.couponCode,
              certIssueDate: item?.certIssueDate ?? formattedStartDate,
              certExpireDate: item.certExpireDate ?? formattedEndDate,
              balance: item.balance ?? formatter.format(+item.offerDescription?.replace(/\D/g, '')),
              sortDate: new Date(+dateSliced[2], +dateSliced[1], +dateSliced[0]).toISOString()
            };
          })
          .sort((a: any, b: any) => {
            return a.sortDate.localeCompare(b.sortDate);
          });

        return activeVouchersAndCoupons;
      }

      return activeVouchers;
    },
    voucherTotalBalance(includeCoupons: boolean) {
      const formatter = Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' });
      if (!self?.profile?.certificates?.length && !self?.profile?.coupons?.length) {
        return formatter.format(0);
      }

      const total =
        (self.profile?.certificates
          ?.filter(
            ({ certExpireDate, certAmountRemaining, certNumber }, index, self) =>
              +new Date(certExpireDate as string) > +new Date() &&
              certAmountRemaining > 0 &&
              self.findIndex((_) => _.certNumber === certNumber) === index
          )
          ?.reduce((acc, { certAmountRemaining }) => acc + certAmountRemaining, 0) ?? 0) +
        (includeCoupons &&
          self.profile?.coupons
            ?.filter(({ availableUses }) => availableUses && availableUses > 0)

            ?.reduce(
              (acc: any, { offerDescription }: any) => acc + +offerDescription?.replace(/\D/g, ''),
              0 ?? 0
            ));

      return formatter.format(total);
    },
    birthdayVoucher() {
      if (!self?.profile?.products?.length) {
        return null;
      }
      const matchingVoucher = self?.profile?.products?.find(({ code }) => code?.includes('BDAY'));
      if (matchingVoucher) {
        return {
          ...matchingVoucher,
          pointCost: matchingVoucher?.minAmount
        };
      }
      return matchingVoucher;
    },
    products() {
      if (!self?.profile?.products?.length) {
        return [];
      }
      const currentBalance = self?.profile?.summary?.pointsInfo?.balance || 0;
      const products = self?.profile?.products
        ?.filter(({ code, webRedeemableInd }) => {
          if (code?.includes('BDAY')) {
            return false;
          }
          if (webRedeemableInd) {
            return true;
          }
          return false;
        })
        ?.map(
          ({
            name,
            description,
            code,
            minAmount,
            maxAmount,
            id,
            inventoryItemId,
            categories,
            images
          }) => ({
            name,
            description,
            code,
            images,
            pointCost: minAmount,
            maxPointCost: maxAmount,
            id,
            inventoryItemId,
            category: categories?.[0]?.code
          })
        );
      const otherProducts = products?.filter(({ category }) => category === 'OTHER');
      const voucherProducts = products
        ?.filter(({ category }) => category === 'VOUCHERS')
        ?.sort((a, b) => {
          return a.pointCost - b.pointCost;
        });
      const highestRedeemableVoucher = voucherProducts
        ?.slice()
        ?.reverse()
        ?.find(({ pointCost }) => pointCost <= currentBalance);
      if (highestRedeemableVoucher) {
        let highestRedeemableVoucherIndex = 0;
        voucherProducts.forEach(({ code }, index) => {
          if (code === highestRedeemableVoucher.code) {
            highestRedeemableVoucherIndex = index;
          }
        });
        const nextVoucher = voucherProducts[highestRedeemableVoucherIndex + 1];
        if (nextVoucher) {
          otherProducts.unshift(nextVoucher);
        }
        // Always show the $5 voucher if the user has at least 10k points
        if (currentBalance >= 10000) {
          otherProducts.unshift(highestRedeemableVoucher);
          otherProducts.unshift(voucherProducts[0]);
        } else {
          otherProducts.unshift(highestRedeemableVoucher);
        }
      } else {
        otherProducts.unshift(voucherProducts[0]);
      }

      return otherProducts;
    },
    filteredRewards({
      month,
      year,
      rowsPerPage,
      page = 1
    }: {
      month?: string;
      year?: string;
      rowsPerPage: number;
      page: number;
    }) {
      const allRewards = self?.profile?.rewards;
      if (!allRewards || allRewards?.length === 0) {
        return { total: 0, rewards: [] };
      }

      const reversedRewards = allRewards.slice().reverse();

      const rewardsObj = {
        total: allRewards?.length,
        rewards: reversedRewards.slice(
          page === 1 ? 0 : rowsPerPage * (page - 1),
          rowsPerPage * page
        )
      };

      // Filter the rewards to ones that are between the start of the month/year selected and the start of the following month
      if (month && year) {
        const isDecember = months.indexOf(month) === months.length - 1;
        const nextMonth = isDecember ? months[0] : months[months.indexOf(month) + 1];
        const nextYear = isDecember ? years[years.indexOf(year) - 1] : null;
        const startDate = new Date(`${month} 1, ${year}`);
        const endDate = new Date(`${nextMonth} 1, ${nextYear || year}`);

        const dateFilteredRewards = reversedRewards.filter(({ activeDate }) => {
          const rewardDate = new Date(activeDate);
          return rewardDate >= startDate && rewardDate < endDate;
        });
        rewardsObj.total = dateFilteredRewards.length;
        rewardsObj.rewards = dateFilteredRewards.slice(
          page === 1 ? 0 : rowsPerPage * (page - 1),
          rowsPerPage * page
        );
      }

      return rewardsObj;
    },
    activeInterests() {
      const interests = self.profile.interests;
      if (!interests?.length) {
        return [];
      }
      return interests?.filter(({ status }: { status: string }) => status === 'A');
    }
  }))
  .actions((self) => ({
    logout() {
      removeCookies(AUTH_TOKEN_COOKIE);
      removeCookies(REFRESH_TOKEN_COOKIE);
      removeCookies(USER_ID_COOKIE);
      removeCookies(CUSTOMER_ID_COOKIE);
      self.profile = {
        summary: undefined,
        rewardsSummary: undefined,
        rewards: undefined,
        certificateSummary: undefined,
        certificates: undefined,
        coupons: null,
        offers: null,
        tierSummary: null,
        products: null,
        interests: null,
        communicationPreferences: undefined,
        session: null
      };
    }
  }))
  .actions((self) => ({
    // this is only meant for sign-up (or any other potential logged-out site functionality that requires Kobie)
    fetchAdminToken: flow(function* fetchToken(
      username = ADMIN_USERNAME,
      password = ADMIN_PASSWORD,
      admin = true
    ) {
      const token = yield extrasApi.tokenRequest(username, password, admin);
      if (token?.access_token) {
        return token.access_token;
      }
    }),
    getMemberCommunicationPreferences: flow(function* getMemberCommunicationPreferences() {
      const res = yield fetch(`/api/extras/communication-preferences?userId=${self.userId}`);
      const json = yield res.json();
      if (json?.success) {
        self.profile.communicationPreferences = json;
      } else if (json?.logout) {
        self.logout();
      }
    }),
    getMemberInterests: flow(function* getMemberInterests() {
      const res = yield fetch(`/api/extras/interests?userId=${self.userId}`);
      const json = yield res.json();
      if (json?.memberPreference) {
        self.profile.interests = json.memberPreference;
      } else if (json?.logout) {
        self.logout();
      }
    }),
    getMemberRewards: flow(function* getMemberRewards(month, year, lastThreeMonths) {
      const date = new Date(`${month} 1, ${year}`);
      let startDate = getFirstDayOfMonth(date);
      const endDate = getLastDayOfMonth(date);
      if (lastThreeMonths) {
        startDate = getThreeMonthsAgo(new Date());
      }
      const res = yield fetch(
        `/api/extras/memberRewards?userId=${self.userId}&startDate=${startDate}&endDate=${endDate}`
      );
      const json = yield res.json();
      if (json?.rewards) {
        if (self.profile.rewards) {
          const currentRewards = self.profile.rewards;
          self.profile.rewards = json.rewards.concat(currentRewards);
        } else {
          self.profile.rewards = json.rewards;
        }
      } else if (json?.logout) {
        self.logout();
      }
    }),
    setSessionValue: flow(function* setSessionValue(){
      self.session.isOldSession = true;
    })
  }))
  .actions((self) => ({
    updatePassword: flow(function* updatePassword(currentPassword: string, password: string) {
      const body = {
        payload: {
          updatePassword: {
            currentPassword,
            password
          }
        }
      };
      const response = yield extrasApi.updatePassword(self.token, self.userId, body);
      return response;
    }),
    passwordReset: flow(function* passwordReset(username) {
      const body = {
        payload: {
          resetPassword: {
            username
          }
        }
      };
      const res = yield extrasApi.passwordReset(body);
      return res;
    }),
    clearUserSession: flow(function* clearUserSession() {
      yield extrasApi.clearUserSession(self.token);
    }),
    updateMemberInfo: flow(function* updateMemberInfo(data) {
      const {
        addressLine1,
        addressLine2,
        addressId,
        postalCode,
        stateProvinceCode,
        city,
        emailAddress,
        ...rest
      } = data;
      const body = {
        payload: {
          member: {
            addresses: [
              {
                addressLine1,
                addressLine2,
                city,
                postalCode,
                stateProvinceCode,
                locationCode: 'H',
                countryCode: 'CA',
                status: 'A',
                addressId
              }
            ],
            ...(emailAddress !== self.email && {
              emails: [
                {
                  emailAddress: data.emailAddress,
                  locationCode: 'H',
                  status: 'A',
                  deliverabilityCode: 'G',
                  preferredInd: true
                }
              ]
            }),
            ...rest
          }
        }
      };
      const res = yield fetch(
        `/api/extras/updateMemberInfo?userId=${self.userId}&customerId=${self.customerId
        }&updatePhone=${data.phoneNumber !== self.phoneNumber}&phoneNumber=${data.phoneNumber}`,
        {
          method: FETCH_METHODS.PATCH,
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify(body)
        }
      );
      return res;
    }),
    updateMemberCommunicationPreferences: flow(function* updateMemberCommunicationPreferences(
      data
    ) {
      const emailPrefs = self.communicationPrefs('email');
      const phonePrefs = self.communicationPrefs('phone');
      const body = {
        payload: {
          communicationPreferences: [
            {
              communicationId: phonePrefs?.communicationId,
              optedInInd: !!data?.sms
            },
            {
              communicationId: emailPrefs?.communicationId,
              optedInInd: !!data?.email
            }
          ]
        }
      };
      const res = yield fetch(
        `/api/extras/communication-preferences/update?userId=${self.userId}`,
        {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      const json = yield res.json();
      if (json?.success) {
        yield self.getMemberCommunicationPreferences();
      } else if (json?.logout) {
        self.logout();
      }
      return json;
    }),
    updateMemberInterests: flow(function* updateMemberInterests(data: InterestsFormObject) {
      const allInterests = self?.profile?.interests;
      const interestsData = Object.keys(data)
        .map((key: string) => {
          return {
            preferenceCode: key,
            status: !!data[key] ? STATUSES.ACTIVE : STATUSES.INACTIVE
          };
        })
        .filter(({ status, preferenceCode }) => {
          const matchingInterest = allInterests?.find(
            (interest) => preferenceCode === interest.preferenceCode
          );
          if (matchingInterest?.status !== 'N/A') {
            return true;
          } else if (matchingInterest?.status === 'N/A' && status === 'A') {
            return true;
          }
          return false;
        });

      const body = {
        payload: {
          memberPreference: interestsData
        }
      };
      const res = yield fetch(`/api/extras/interests/update?userId=${self.userId}`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      yield self.getMemberInterests();
      // add preferences to store here?
      return res;
    }),
    registerAccountOffer: flow(function* registerAccountOffer(offerId, isTargetedOffer) {
      try {
        const registrationDate = getFormattedDate(new Date(), true);
        if (isTargetedOffer) {
          const body = {
            payload: {
              updateAccountOfferRegistrationDate: {
                registrationDate
              }
            }
          };
          const response = yield fetch(
            `/api/extras/offers/registerOffer?userId=${self.userId}&offerId=${offerId}`,
            {
              method: 'POST',
              body: JSON.stringify(body),
              headers: { 'Content-Type': 'application/json' }
            }
          );
          const json = yield response.json();
          if (json?.hasError && json?.error?.errorCode === '-2107') {
            Sentry.captureMessage(`Date sent to Kobie: ${registrationDate}`);
          } else if (json?.logout) {
            self.logout();
          }
          return json;
        } else {
          const body = {
            payload: {
              createAccountOfferDTO: {
                registrationStartDate: registrationDate,
                offerCode: offerId,
                status: 'A'
              }
            }
          };
          const response = yield fetch(
            `/api/extras/offers/registerOffer?userId=${self.userId}&offerCode=${offerId}`,
            {
              method: 'POST',
              body: JSON.stringify(body),
              headers: { 'Content-Type': 'application/json' }
            }
          );
          const json = yield response.json();
          return json;
        }
      } catch (error) {
        console.error('ERROR REGISTERING ACCOUNT OFFER');
        console.error(error);
      }
    }),
    registerLoadableOffer: flow(function* registerLoadableOffer(offerId, offerData) {
      try {
        const registrationDate = getFormattedDate(new Date(), true);
        const body = {
          payload: {
            createAccountOfferDTO: {
              registrationStartDate: registrationDate,
              offerCode: offerId,
              status: 'A',
              startDate: offerData?.startedDate,
              endDate: offerData?.expiresDate
            }
          }
        };
        const response = yield fetch(
          `/api/extras/offers/registerOffer?userId=${self.userId}&offerCode=${offerId}`,
          {
            method: 'POST',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' }
          }
        );
        const json = yield response.json();
        return json;
      } catch (error) {
        console.error('ERROR REGISTERING ACCOUNT OFFER');
        console.error(error);
      }
    }),
    gatherMemberData: flow(function* gatherMemberData() {
      if (self.userId) {
        const currentProfile = self.profile || {};
        const response = yield fetch(`/api/extras/gatherMemberData?userId=${self.userId}`);
        const profile = yield response.json();
        if (profile?.logout) {
          self.logout();
        } else {
          self.profile = { ...currentProfile, ...profile };
        }
      }
    }),
    getUpdateOffer: flow(function* getUpdateOffer(offerCode: any) {
      const offers = self?.profile?.offers?.map((_offer) => {
        if (_offer?.offerCode === offerCode) {
          _offer.registeredDate = new Date().toISOString();
          return _offer;
        }
        return _offer
      });
      // @ts-ignore
      self.profile.offers = offers
    }),
    getProducts: flow(function* getProducts() {
      const response = yield fetch(`/api/extras/products`);
      const json = yield response.json();
      if (json?.success && json?.products) {
        self.profile.products = json.products;
      }
    })
  }))
  .actions((self) => ({
    login: flow(function* login({ username, password }) {
      const response = yield fetch(
        `/api/auth/fetch-token?username=${unMaskPhoneValue(username)}&password=${encodeURIComponent(
          password
        )}`
      );
      const json = yield response.json();
      if (json?.access_token) {
        self.token = json.access_token;
        self.userId = json?.accountUuid?.toString();
        self.customerId = json?.customerUuid?.toString();
      }
      return json;
    }),
    checkPasswordStatus: flow(function* checkPasswordStatus() {
      const response = yield fetch(`/api/auth/check-password-status?token=${self.token}`);
      const json = yield response.json();
      return json;
    }),
    redeemProduct: flow(function* redeemProduct(redemptionId) {
      const response = yield fetch(
        `/api/extras/products/redeem?redemptionId=${redemptionId}&userId=${self.userId
        }&emailAddress=${encodeURIComponent(
          self?.profile?.summary?.emailAddress || ''
        )}&firstName=${self?.profile?.summary?.firstName}&stateProvinceCode=${self?.profile?.summary?.address?.stateProvinceCode
        }&countryCode=${self?.profile?.summary?.address?.countryCode || 'CA'}`
      );
      const json = yield response.json();
      if (json?.success) {
        self.gatherMemberData();
      }
      return json;
    }),
    loadableOffers() {
      if (!self?.profile?.offers?.length) {
        return null;
      }
      const matchingOffers = self?.profile?.offers
        ?.filter(
          // Filtering the offer data if it contains 'CPN'
          (_offer) =>
            _offer?.offerCode?.substring(0, 3) === 'CPN' && !isFrequencyOffer(_offer.offerCode)
        )
        ?.filter(({ expiresDate, startedDate }) => {
          // const startDate = getDate(startedDate as string); // +convertDateToPST(startedDate as string);
          // const expirationDate = getDate(expiresDate as string); //+convertOfferExpirationDate(expiresDate as string);
          // const now = getDate(`${new Date()}`); //Date.now();
          const startDate = +convertDateToPST(startedDate as string);
          const expirationDate = +convertOfferExpirationDate(expiresDate as string);
          const now = Date.now();
          if (startDate > now) {
            return false;
          } else if (expirationDate < now) {
          // } else if (expiresDate && expirationDate < now) {
            return false;
          }
          return true;
        })
        ?.sort((a: any, b: any) =>a?.offerCode?.localeCompare(b?.offerCode));
      if (matchingOffers?.length > 0) {
        return matchingOffers.map((offer) => {
          const { expiresDate, description, imageLink, registeredDate } = offer;
          return {
            ...offer,
            endDate:
              expiresDate === null
                ? 'No expiration date'
                : `${moment
                  // .utc(new Date(expiresDate).toUTCString())
                  .utc(new Date(convertOfferExpirationDate(expiresDate, 2, true)).toUTCString())
                  .format('MMM DD YYYY')}`,
            headline: description,
            image: {
              url:
                !imageLink || (typeof imageLink === 'string' && !imageLink.startsWith('http'))
                  ? '/assets/location-placeholder.jpeg'
                  : imageLink
            },
            isRegistered: !!registeredDate,
            isTargetedOffer: !!offer?.offerCode?.includes('SWPTGT')
          };
        });
      }
      return null;
    },
    loadableFeaturedOffers() {
      if (!self?.profile?.offers?.length) {
        return null;
      }
      const matchingOffers = self?.profile?.offers
        ?.filter(
          // Filtering the offer data if it contains 'CPN'
          (_offer) => _offer?.offerCode?.substring(0, 3) === 'CPN' && isFrequencyOffer(_offer.offerCode)
        )
        ?.filter(({ expiresDate, startedDate }) => {
          // const startDate = getDate(startedDate as string) //+convertDateToPST(startedDate as string);
          // const expirationDate = getDate(expiresDate as string) // +convertOfferExpirationDate(expiresDate as string);
          // const now = getDate(`${new Date()}`);
          const startDate = +convertDateToPST(startedDate as string);
          const expirationDate = +convertOfferExpirationDate(expiresDate as string);
          const now = Date.now();
          if (startDate > now) {
            return false;
          } else if (expirationDate < now) {
          // } else if (expiresDate && expirationDate < now) {
            return false;
          }
          return true;
        })
        ?.sort((a: any, b: any) =>a?.offerCode?.localeCompare(b?.offerCode));
      if (matchingOffers?.length > 0) {
        return matchingOffers.map((offer) => {
          const { expiresDate, description, imageLink, registeredDate } = offer;
          return {
            ...offer,
            endDate:
              expiresDate === null
                ? 'No expiration date'
                : `Ends: ${moment
                  // .utc(new Date(expiresDate).toUTCString())
                  .utc(new Date(convertOfferExpirationDate(expiresDate, 2, true)).toUTCString())
                  .format('MMM DD YYYY')}`,
            headline: description,
            image: {
              url:
                !imageLink || (typeof imageLink === 'string' && !imageLink.startsWith('http'))
                  ? '/assets/location-placeholder.jpeg'
                  : imageLink
            },
            isRegistered: !!registeredDate,
            isTargetedOffer: !!offer?.offerCode?.includes('SWPTGT')
          };
        });
      }
      return null;
    }
  }))
  .actions((self) => ({
    signUp: flow(function* signUp(data) {
      // re-format the dob field to what the API expects
      const [month, day, year] = data.dobDate.split('/');
      const dobDate = `${year}-${month}-${day}`;

      // These are only for form data validation purposes, not needed in the registration request
      delete data.confirmPhoneNumber;
      delete data.confirmEmail;
      delete data.confirmUsername;
      delete data.confirmPassword;
      delete data.agreeToTerms;
      delete data.registerForSMS;

      const body = {
        clientProperties: [
          {
            key: 'string',
            value: 'string'
          }
        ],
        payload: {
          member: {
            ...data,
            addressLine1: data?.addressLine1 || 'Please update',
            // default for enrollment
            addressDeliverabilityCode: 'G',
            // H - home
            addressLocationCode: 'H',
            // I - individual
            customerType: 'I',
            // Always CA
            countryCode: 'CA',
            dobDate,
            // G - agree to emails, N - do not send emails
            emailDeliveryabilityCode: data.emailDeliveryabilityCode === 'yes' ? 'G' : 'N',
            // We don't ask for this, default to H (home)
            emailLocationCode: 'H',
            // Always EN
            languageCode: 'EN',
            // Always CA
            phoneCountryCode: 'CA',
            // G - agree to contact, N - do not contact
            phoneDeliverabilityCode: !!data.phoneDeliverabilityCode ? 'G' : 'N',
            // We don't ask for this, default to C (cellphone)
            phoneLocationCode: 'C',
            // Where this registration took place
            sourceCode: data?.sourceCode || 'WEBSITE',
            // A - Active
            status: STATUSES.ACTIVE
          }
        }
      };
      const token = yield self.fetchAdminToken();
      const res = yield extrasApi.register(token, body);
      if (res?.payload?.id) {
        yield self.login({ username: data.userName, password: data.password });
      }
      return res;
    })
  }));
